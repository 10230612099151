import {createContext} from 'react'

export interface IProPaymentProps {
    plan?: string
    setPlan: (plan?: string) => void
    selectedPlan?: IProPlan
    paymentMethod?: string
    setPaymentMethod: (method?: string) => void
    paymentCurrency?: string
    setPaymentCurrency: (currency?: string) => void
    paymentNetwork?: string
    setPaymentNetwork: (network?: string) => void
    isConfirmed?: boolean
    setIsConfirmed: (isConfirmed?: boolean) => void
    isProPaid?: boolean
    setIsProPaid: (isProPaid?: boolean) => void
    isSupportMetamask?: boolean
    isSupportCoinbaseWallet?: boolean
    isMobileConfirmedMethod?: boolean
    setIsMobileConfirmedMethod?: (isConfirmed?: boolean) => void
}

export const ProPaymentContext = createContext<IProPaymentProps>({
    plan: '12months',
    setPlan: (plan?: string) => {},
    selectedPlan: undefined,
    paymentMethod: undefined,
    setPaymentMethod: (method?: string) => {},
    paymentCurrency: undefined,
    setPaymentCurrency: (currency?: string) => {},
    paymentNetwork: undefined,
    setPaymentNetwork: (network?: string) => {},
    isConfirmed: false,
    setIsConfirmed: (isConfirmed?: boolean) => {},
    isProPaid: false,
    setIsProPaid: (isProPaid?: boolean) => {},
    isSupportMetamask: false,
    isSupportCoinbaseWallet: false,
    isMobileConfirmedMethod: false,
    setIsMobileConfirmedMethod: (isConfirmed?: boolean) => {},
})

export interface IProPlan {
    id: string
    month: number
    price: number
    discountPercent?: number
    isBestPlan?: boolean
}

export const proPlans: IProPlan[] = [
    {
        id: '1month',
        month: 1,
        price: 12,
    },
    {
        id: '12months',
        month: 12,
        price: 96,
        discountPercent: 33.3,
        isBestPlan: true,
    },
    {
        id: '6months',
        month: 6,
        price: 60,
        discountPercent: 16.7,
    },
]
