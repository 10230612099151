import React, {useContext, useRef} from 'react'
import {IBaseProps} from '@type/page'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import useQueryGetDepositAddressCrypto from '@hook/query/user/account/wallet/useQueryDepositAddressCrypto'
import QRCode from 'react-qr-code'
import WizButton from '@component/buttons/WizButton'

import IconCopy from '@svg/common/ic_copy.svg'

import useCopy from '@hook/useCopy'
import TextReadOnlyInput from '../component/TextReadOnlyInput'
import TagSpan from '@component/Text/TagSpan'
import useFormatNumber from '@hook/useFormatNumber'
import {equalString} from '@util/strings'
import {ProPaymentContext} from '../common'
import ProPaymentExternalCryptoModal from '../ProPaymentExternalCryptoModal/ProPaymentExternalCryptoModal'
import useSnackbar from '@hook/useSnackbar'
import useQueryDepositCurrencies from '@hook/query/user/account/wallet/useQueryDepositCurrencies'
import IconErrorMessage from '@svg/common/ic_error_message.svg'
import IconMetaMask from '@svg/wallet/ic_metamask.svg'
import {createLog} from '@util/logs'

interface IProps extends IBaseProps {}

const isSupportCoinbaseWallet = false
const ProPaymentDetailDefaultWalletContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {paymentNetwork, paymentCurrency, selectedPlan, isSupportMetamask, isSupportCoinbaseWallet} =
        useContext(ProPaymentContext)
    const {data: depositOptions} = useQueryDepositCurrencies()
    const {data: depositAddresses} = useQueryGetDepositAddressCrypto('lot-pro', paymentNetwork)
    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()
    const {displayNumber} = useFormatNumber()
    const payModalRef = useRef(null)

    return (
        <>
            <div className={`${className}`}>
                <Text className={'text-h3 text-gray01 dark:text-dark_gray01'}>
                    {t('landingPayment.paymentDetail.title')}
                </Text>
                <div className={'flex flex-col mt-[10px]'}>
                    <div className={'flex flex-col items-center justify-center'}>
                        <div className={'p-[5px] bg-white rounded-[3px]'}>
                            {depositAddresses?.address && (
                                <QRCode size={150} value={depositAddresses?.address ?? ''} viewBox={`0 0 150 150`} />
                            )}
                        </div>
                        <Text className={'text-body1 font-bold mt-[10px] text-gray01 dark:text-dark_gray01'}>
                            {t('landingPayment.paymentDetail.defaultWallet.qr.guide')}
                        </Text>
                        {isSupportMetamask && (
                            <>
                                <div className={'flex relative py-[10px] mt-[10px] max-w-[230px] w-full'}>
                                    <div
                                        className={'flex-1 border-gray07 dark:border-dark_gray07  border-t mt-[12px]'}
                                    />
                                    <Text
                                        className={
                                            'text-body1 text-gray03 dark:text-dark_gray03 text-center px-[10px]'
                                        }>
                                        {t('landingPayment.paymentDetail.or')}
                                    </Text>
                                    <div
                                        className={'flex-1 border-gray07 dark:border-dark_gray07 border-t mt-[12px]'}
                                    />
                                </div>
                                <WizButton
                                    buttonType={'border'}
                                    text={t('landingPayment.paymentDetail.defaultWallet.connectYourWallet')}
                                    className={'mt-[20px]'}
                                    renderRightArea={
                                        <div className={'flex gap-x-[10px] items-center'}>
                                            {isSupportMetamask && <IconMetaMask className={'w-[14px]'} />}
                                        </div>
                                    }
                                    onClick={() => {
                                        createLog('event', 'account_pro_payment_connect_metamask')
                                        payModalRef?.current?.show()
                                    }}
                                />
                            </>
                        )}
                        <div className={'flex relative py-[10px] mt-[20px] max-w-[230px] w-full '}>
                            <div className={'flex-1 border-gray07 dark:border-dark_gray07  border-t mt-[12px]'} />
                            <Text className={'text-body1 text-gray03 dark:text-dark_gray03 text-center px-[10px]'}>
                                {t('landingPayment.paymentDetail.or')}
                            </Text>
                            <div className={'flex-1 border-gray07 dark:border-dark_gray07 border-t mt-[12px]'} />
                        </div>
                    </div>

                    <Text className={'text-body1 font-bold text-gray01 dark:text-dark_gray01'}>
                        {t('landingPayment.paymentDetail.defaultWallet.sendThisAmount.title')}
                    </Text>
                    <TextReadOnlyInput
                        text={`${displayNumber(selectedPlan?.price, 2)} ${paymentCurrency?.toUpperCase()}`}
                        renderInputRightArea={
                            <div
                                className={'flex gap-x-[5px] cursor-pointer items-center'}
                                onClick={() => {
                                    createLog('event', 'account_pro_payment_copy_amount')
                                    copyToClipboard(displayNumber(selectedPlan?.price, 2))
                                    showSnackbar(t('common.copied'))
                                }}>
                                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:font-bold'}>
                                    {t('landingPayment.paymentDetail.defaultWallet.sendThisAmount.clickToCopy')}
                                </Text>
                                <IconCopy
                                    className={'flex-none sm:hidden w-[16px] fill-gray02 dark:fill-dark_gray02'}
                                />
                            </div>
                        }
                    />
                    <Text className={'text-body1 font-bold mt-[20px] text-gray01 dark:text-dark_gray01'}>
                        {t('landingPayment.paymentDetail.defaultWallet.address.title')}
                    </Text>
                    <TextReadOnlyInput
                        text={depositAddresses?.address}
                        renderInputRightArea={
                            <div
                                className={'flex gap-x-[5px] cursor-pointer items-center'}
                                onClick={() => {
                                    createLog('event', 'account_pro_payment_copy_address')
                                    copyToClipboard(depositAddresses?.address)
                                    showSnackbar(t('common.copied'))
                                }}>
                                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 sm:font-bold'}>
                                    {t('landingPayment.paymentDetail.defaultWallet.address.clickToCopy')}
                                </Text>
                                <IconCopy
                                    className={'flex-none sm:hidden w-[16px] fill-gray02 dark:fill-dark_gray02'}
                                />
                            </div>
                        }
                    />
                    <ul className={'list-disc mt-[30px] list-outside pl-[20px] text-gray02 dark:text-dark_gray02'}>
                        <li>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('landingPayment.paymentDetail.defaultWallet.address.option1', {
                                    image_url: depositOptions?.currencies?.find(item =>
                                        equalString(item.symbol, paymentCurrency),
                                    )?.logo,
                                    symbol: depositOptions?.currencies?.find(item =>
                                        equalString(item.symbol, paymentCurrency),
                                    )?.symbol,
                                })}
                            </TagSpan>
                        </li>
                        <li>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('landingPayment.paymentDetail.defaultWallet.address.option2', {
                                    symbol: depositOptions?.networks?.find(item =>
                                        equalString(item.key, paymentNetwork),
                                    )?.name,
                                })}
                            </TagSpan>
                        </li>
                    </ul>

                    <div className={'flex items-center space-x-[5px] mt-[40px]'}>
                        <IconErrorMessage className={'w-[16px] flex-none fill-red dark:fill-dark_red'} />
                        <Text className={'text-body1 font-bold text-gray01 dark:text-dark_gray01'}>
                            {t('landingPayment.paymentDetail.defaultWallet.importantNotes.title')}
                        </Text>
                    </div>
                    <ul className={'list-disc list-outside mt-[10px] pl-[20px] text-gray02 dark:text-dark_gray02'}>
                        <li className={'text-red_shade dark:text-dark_red_shade'}>
                            <Text>
                                {t('warning.common.deposit.network', {
                                    network: depositOptions?.networks?.find(item => item?.key === paymentNetwork)?.name,
                                })}
                            </Text>
                        </li>
                        <li>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('landingPayment.paymentDetail.defaultWallet.importantNotes.option1')}
                            </TagSpan>
                        </li>
                        <li>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('landingPayment.paymentDetail.defaultWallet.importantNotes.option2')}
                            </TagSpan>
                        </li>
                    </ul>
                </div>
            </div>
            <ProPaymentExternalCryptoModal ref={payModalRef} />
        </>
    )
}

export default ProPaymentDetailDefaultWalletContainer
