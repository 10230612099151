import React, {useMemo} from 'react'
import WizNavTabs, {Tab} from '@component/tabs/WizNavTabs'
import {getLinkUrl, URL_ACCOUNT_TERM_CONDITIONS, URL_ACCOUNT_TERM_LIVE, URL_ACCOUNT_TERM_PRIVACY} from '@constant/url'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
}

const TermsNavTabs: React.FC<IProps> = ({className}) => {
    const router = useRouter()
    const {t} = useTranslation()

    const TABS: Tab[] = useMemo(
        () => [
            {
                id: ['/terms/privacy'],
                label: t('footer.menu.privacy'),
                link: getLinkUrl(URL_ACCOUNT_TERM_PRIVACY),
            },
            {
                id: ['/terms/terms-and-conditions'],
                label: t('footer.menu.termsAndConditions'),
                link: getLinkUrl(URL_ACCOUNT_TERM_CONDITIONS),
            },
            {
                id: ['/terms/live-policy'],
                label: t('footer.menu.live.policy'),
                link: getLinkUrl(URL_ACCOUNT_TERM_LIVE),
            },
        ],
        [t],
    )

    return (
        <WizNavTabs
            type={'links'}
            tabs={TABS}
            selectedTab={router?.pathname}
            className={'w-full !justify-start mb-[25px] border-b border-gray07 dark:border-dark_gray07'}
        />
    )
}
export default TermsNavTabs
