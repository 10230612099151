import React, {useContext, useEffect, useState} from 'react'
import {IBaseProps} from '@type/page'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import WizImage from '@component/images/WizImage'
import IconNewWindow from '@svg/common/ic_new_window.svg'
import TagSpan from '@component/Text/TagSpan'
import {ProPaymentContext} from '../common'
import useQueryGetProBoot from '@hook/query/account/pro/useQueryGetProBoot'
import useQueryGetProPayConfirmStatus from '@hook/query/account/pro/useQueryGetProPayConfirmStatus'
import {apiPostPayPro, IResPostPayPro} from '@api/user/account/pro/postPayPro'
import {isNotEmptyArray} from '@util/array'
import {createLog} from '@util/logs'
import useDidMountEffect from '@hook/UseDidMountEffect'

interface IProps extends IBaseProps {}

const ProPaymentDetailBinancePayContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {selectedPlan, setIsProPaid} = useContext(ProPaymentContext)
    const {data: proBoot} = useQueryGetProBoot()
    const [payInfo, setPayInfo] = useState<IResPostPayPro>()
    const {data: confirmStatus} = useQueryGetProPayConfirmStatus(payInfo?.lot_id)

    useDidMountEffect(async () => {
        if (proBoot?.bundles && isNotEmptyArray(proBoot?.bundles)) {
            const {data} = await apiPostPayPro(
                proBoot?.bundles?.find(it => it.period === selectedPlan?.month)?.bundle_id,
            )
            setPayInfo(data)
        }
    }, [proBoot?.bundles])

    useEffect(() => {
        switch (confirmStatus?.status) {
            case 'paid':
            case 'processed':
                setIsProPaid(true)
                return
        }
    }, [confirmStatus?.status, setIsProPaid])

    return (
        <>
            <div className={`${className}`}>
                <Text className={'text-h3 text-gray01 dark:text-dark_gray01'}>
                    {t('landingPayment.paymentDetail.binancePay.title')}
                </Text>
                <div className={'flex flex-col mt-[10px]'}>
                    <div className={'flex flex-col items-center justify-center'}>
                        {payInfo?.qrcodeLink && <WizImage src={payInfo?.qrcodeLink} width={150} height={150} />}
                        <Text className={'text-body1 font-bold mt-[10px] text-gray01 dark:text-dark_gray01'}>
                            {t('landingPayment.paymentDetail.binancePay.qr.quide')}
                        </Text>

                        <div className={'flex relative py-[10px] mt-[20px] max-w-[230px] w-full '}>
                            <div className={'flex-1 border-gray07 dark:border-dark_gray07  border-t mt-[12px]'} />
                            <Text className={'text-body1 text-gray03 dark:text-dark_gray03 text-center px-[10px]'}>
                                {t('landingPayment.paymentDetail.or')}
                            </Text>
                            <div className={'flex-1 border-gray07 dark:border-dark_gray07 border-t mt-[12px]'} />
                        </div>

                        <Text className={'text-body1 font-bold mt-[20px] text-gray01 dark:text-dark_gray01'}>
                            {t('landingPayment.paymentDetail.binancePay.goBinance.title')}
                        </Text>
                        <WizButton
                            buttonType={'border'}
                            text={t('landingPayment.paymentDetail.defaultWallet.connectYourWallet')}
                            className={'mt-[6px]'}
                            renderRightArea={<IconNewWindow className={'w-[16px] fill-gray02 dark:fill-dark_gray02'} />}
                            onClick={() => {
                                createLog('event', 'pro_payment_connect_binance_pay')
                                window.open(payInfo?.universalUrl)
                            }}
                        />
                    </div>

                    <ul className={'list-disc list-outside mt-[20px] pl-[20px] text-gray02 dark:text-dark_gray02'}>
                        <li>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('landingPayment.paymentDetail.binancePay.option1')}
                            </TagSpan>
                        </li>
                        <li>
                            <TagSpan className={'text-body1 text-gray02 dark:text-dark_gray02'}>
                                {t('landingPayment.paymentDetail.binancePay.option2')}
                            </TagSpan>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ProPaymentDetailBinancePayContainer
