import React, {useContext} from 'react'
import {IBaseProps} from '@type/page'
import {useTranslation} from 'next-i18next'
import TagText from '@component/Text/TagText'
import {RadioGroup} from '@headlessui/react'
import WizRadioButton from '@component/buttons/WizRadioButton'
import {ProPaymentContext} from '../common'
import BinancePayImageDark from '@image/img_pro_payment_binance_pay_dark.png'
import BinancePayImage from '@image/img_pro_payment_binance_pay.png'
import WizImage from '@component/images/WizImage'
import useDark from '@hook/useDark'
import {createLog} from '@util/logs'

interface IProps extends IBaseProps {}

interface IPaymentMethod {
    id: string
    text: string
}

const ProSelectPaymentMethodContainer: React.FC<IProps> = ({className}) => {
    const {paymentMethod, setPaymentMethod} = useContext(ProPaymentContext)
    const {t} = useTranslation()
    const {isRenderDark} = useDark()
    const items: IPaymentMethod[] = [
        {id: 'default_wallet', text: t('landingPayment.selectPaymentMethod.items.defaultWallet')},
        {id: 'binance_pay', text: t('landingPayment.selectPaymentMethod.items.binancePay')},
    ]

    const onClickMethod = (method: string) => {
        createLog('event', 'account_pro_payment_select_method', {method})
        setPaymentMethod(method)
    }

    return (
        <div className={`${className}`}>
            <RadioGroup
                value={paymentMethod ?? false}
                onChange={onClickMethod}
                className={'grid grid-cols-1 gap-y-[10px]'}>
                {items?.map(item => (
                    <RadioGroup.Option key={item.id} value={item.id}>
                        {({checked}) => (
                            <div
                                className={
                                    'flex bg-white dark:bg-bg_dark_white01 border border-gray07 dark:border-dark_gray07 p-[20px] gap-x-[20px] rounded-[3px] items-center cursor-pointer'
                                }>
                                <WizRadioButton checked={checked} />
                                {item?.id === 'default_wallet' ? (
                                    <TagText className={'text-gray01 dark:text-dark_gray01'}>{item.text}</TagText>
                                ) : (
                                    <div className={'inline-block'}>
                                        <WizImage
                                            src={isRenderDark ? BinancePayImageDark : BinancePayImage}
                                            width={115.04}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </RadioGroup.Option>
                ))}
            </RadioGroup>
        </div>
    )
}

export default ProSelectPaymentMethodContainer
