import {BaseDataFeed, IKlineParams, BarData, TSymbolInfo} from './BaseDataFeed'
import axios from 'axios'
import {webSocketManager} from '@component/websocket/websocket'

export const BINANCE_KLINE_WS = 'wss://fstream.binance.com/ws/btcusdt@kline_1m'
const BINANCE_BASE_URL = 'https://www.binance.com/fapi/v1'
const contractType = 'PERPETUAL'
const limit = 1000
const intervals = {
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '240': '4h',
    '1D': '1d',
    '1W': '1w',
    '1M': '1M',
}

function mapInterval(interval: string): string {
    return intervals[interval] || interval
}

export class BinanceDataFeed extends BaseDataFeed {
    protected socketKey: string

    constructor(socketKey: string) {
        super()
        this.socketKey = socketKey
    }

    public async fetchKlines(params: IKlineParams): Promise<BarData[]> {
        try {
            const interval = mapInterval(params.interval) || '1m'
            const response = await axios.get(`${BINANCE_BASE_URL}/continuousKlines`, {
                params: {
                    ...params,
                    pair: params.symbol,
                    interval,
                    contractType,
                    limit,
                    endTime: params.to * 1000,
                },
            })

            return response.data.map((d: any[]) => ({
                ...d,
                time: d[0],
                open: parseFloat(d[1]),
                high: parseFloat(d[2]),
                low: parseFloat(d[3]),
                close: parseFloat(d[4]),
                volume: parseFloat(d[5]),
            }))
        } catch (error) {
            console.error(`Failed to fetch Binance klines for ${params.symbol}`, error)
            return []
        }
    }

    protected createChannelString(symbol: string, resolution: string): string {
        return `${symbol.toLowerCase()}@kline_${mapInterval(resolution) || '1m'}`
    }

    protected subscribeToChannel(channelString: string): void {
        // console.log('[subscribeBars]: Subscribe to binance streaming. Channel:', channelString)
        const data = {
            method: 'SUBSCRIBE',
            params: [channelString],
            id: 1,
        }
        void webSocketManager.sendMessage(this.socketKey, data)
    }

    protected unsubscribeFromChannel(channelString: string): void {
        // console.log('[unsubscribeBars]: Unsubscribe from binance streaming. Channel:', channelString)
        const data = {
            method: 'UNSUBSCRIBE',
            params: [channelString],
            id: 1,
        }
        void webSocketManager.sendMessage(this.socketKey, data)
    }

    public handleStreamData(data: any): void {
        if (!data.k) return

        const channelString = `${data?.s?.toLowerCase()}@kline_${mapInterval(data?.k?.i) || '1m'}`
        const subscriptionItem = this.channelToSubscription?.get?.(channelString)
        if (!subscriptionItem) return
        const bar: BarData = {
            time: data.k.t,
            open: parseFloat(data.k.o),
            high: parseFloat(data.k.h),
            low: parseFloat(data.k.l),
            close: parseFloat(data.k.c),
            volume: parseFloat(data.k.v),
        }
        subscriptionItem.handlers.forEach(handler => handler.callback(bar))
    }
}

export async function getAllBinanceSymbols(): Promise<TSymbolInfo[]> {
    try {
        const response = await axios.get(`${BINANCE_BASE_URL}/exchangeInfo`)
        const filteredSymbols = response.data?.symbols?.filter(symbol => symbol.status === 'TRADING')
        return filteredSymbols?.map(symbol => ({
            ticker: symbol.symbol,
            name: symbol.baseAsset + symbol.quoteAsset,
            description: symbol.baseAsset + symbol.quoteAsset,
            baseAsset: symbol.baseAsset,
            quoteAsset: symbol.quoteAsset,
            pricescale: symbol.pricePrecision,
            exchange: 'Binance',
        }))
        /*[
            {
                symbol: 'BTCUSDT',
                pair: 'BTCUSDT',
                contractType: 'PERPETUAL',
                deliveryDate: 4133404800000,
                onboardDate: 1569398400000,
                status: 'TRADING',
                maintMarginPercent: '2.5000',
                requiredMarginPercent: '5.0000',
                baseAsset: 'BTC',
                quoteAsset: 'USDT',
                marginAsset: 'USDT',
                pricePrecision: 2,
                ...
            },
        ]*/
    } catch (e) {
        console.log('binance get all symbols error:', e)
    }
}
