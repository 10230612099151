const ORDER_PRICE_RANGE = {
    LESS_THAN_OR_EQUAL_100: 'LESS_THAN_OR_EQUAL_100',
    BETWEEN_100_AND_1000: 'BETWEEN_100_AND_1000',
    BETWEEN_1000_AND_10000: 'BETWEEN_1000_AND_10000',
    BETWEEN_10000_AND_100000: 'BETWEEN_10000_AND_100000',
    GREATER_THAN_100000: 'greater_than_100000',
} as const

type TOrderPriceRange = (typeof ORDER_PRICE_RANGE)[keyof typeof ORDER_PRICE_RANGE]

export const getOrderPriceRange = (amount: number = 0): TOrderPriceRange => {
    if (amount > 100000) {
        return ORDER_PRICE_RANGE.GREATER_THAN_100000
    } else if (amount > 10000) {
        return ORDER_PRICE_RANGE.BETWEEN_10000_AND_100000
    } else if (amount > 1000) {
        return ORDER_PRICE_RANGE.BETWEEN_1000_AND_10000
    } else if (amount > 100) {
        return ORDER_PRICE_RANGE.BETWEEN_100_AND_1000
    } else {
        return ORDER_PRICE_RANGE.LESS_THAN_OR_EQUAL_100
    }
}

export const getColor = (amount?: number, isBuy?: boolean, isDark?: boolean) => {
    let buyColor = isDark ? '#418549' : '#A6DE53'
    let sellColor = isDark ? '#B81C1E' : '#FAA7A5'
    switch (getOrderPriceRange(amount || 0)) {
        case ORDER_PRICE_RANGE.BETWEEN_100_AND_1000:
            buyColor = isDark ? '#569B4E' : '#87C853'
            sellColor = isDark ? '#E43126' : '#D56F6E'
            break
        case ORDER_PRICE_RANGE.BETWEEN_1000_AND_10000:
            buyColor = '#6EB252'
            sellColor = '#DD504D'
            break
        case ORDER_PRICE_RANGE.BETWEEN_10000_AND_100000:
            buyColor = isDark ? '#87C853' : '#569B4E'
            sellColor = isDark ? '#D56F6E' : '#E43126'
            break
        case ORDER_PRICE_RANGE.GREATER_THAN_100000:
            buyColor = isDark ? '#A6DE53' : '#418549'
            sellColor = isDark ? '#FAA7A5' : '#B81C1E'
            break
    }

    return isBuy ? buyColor : sellColor
}
export const getFontSize = (amount?: number) => {
    switch (getOrderPriceRange(amount || 0)) {
        case ORDER_PRICE_RANGE.LESS_THAN_OR_EQUAL_100:
            return `8px sans-serif`
        case ORDER_PRICE_RANGE.BETWEEN_100_AND_1000:
            return `11px sans-serif`
        case ORDER_PRICE_RANGE.BETWEEN_1000_AND_10000:
            return `13px sans-serif`
        case ORDER_PRICE_RANGE.BETWEEN_10000_AND_100000:
            return `16px sans-serif`
        case ORDER_PRICE_RANGE.GREATER_THAN_100000:
            return `20px sans-serif`
    }
}

export const getHeight = (amount?: number) => {
    switch (getOrderPriceRange(amount || 0)) {
        case ORDER_PRICE_RANGE.LESS_THAN_OR_EQUAL_100:
            return 4
        case ORDER_PRICE_RANGE.BETWEEN_100_AND_1000:
            return 6
        case ORDER_PRICE_RANGE.BETWEEN_1000_AND_10000:
            return 8
        case ORDER_PRICE_RANGE.BETWEEN_10000_AND_100000:
            return 10
        case ORDER_PRICE_RANGE.GREATER_THAN_100000:
            return 12
    }
}
