import React from 'react'
import {IBaseProps} from '@type/page'
import Text from '@component/Text/Text'

interface IProps extends IBaseProps {
    text?: string
    renderInputRightArea?: JSX.Element
}

const TextReadOnlyInput: React.FC<IProps> = ({className, text, renderInputRightArea}) => {
    return (
        <div
            className={`${className} bg-gray09 dark:bg-dark_gray09 border border-gray07 dark:border-dark_gray07 rounded-[3px] mt-[5px] py-[15px] px-[20px] flex gap-x-[30px]`}>
            <Text className={'flex-1 break-all text-gray01 dark:text-dark_gray01'}>{text}</Text>
            {renderInputRightArea}
        </div>
    )
}

export default TextReadOnlyInput
