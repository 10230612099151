import React from 'react'
import {useTranslation} from 'next-i18next'

import {DarkModeStore} from '@store/DarkmodeStore'

import Text from '@component/Text/Text'
import WizTooltip from '@component/tooltip/WizTooltip'

import IconInfoDark from '@svg/common/ic_info_dark.svg'
import Info from '@svg/common/ic_info.svg'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

const ChartTooltipItem: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const isDark = DarkModeStore(state => state.isDark)
    return (
        <WizTooltip
            type={'trading_view_chart_community_trades'}
            title={t('chat.community.trades.title')}
            panelClassName={'!max-w-[400px]'}
            onClick={e => {
                e.stopPropagation()
                createLog('event', 'chart_info_display_trades')
            }}
            render={
                isDark ? (
                    <IconInfoDark className={'w-[20px] fill-bg_dark_white03 hover:fill-bg_dark_white01'} />
                ) : (
                    <Info className={'w-[20px] fill-white hover:fill-gray08'} />
                )
            }>
            <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mb-[10px]'}>
                {t('chat.community.trades.desc')}
            </Text>
            <ul className={'space-y-[10px]'}>
                <li>
                    <div className={'flex items-center gap-x-[5px]'}>
                        <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>
                            {t('chat.community.trades.option1')}:
                        </Text>
                        <div className={'w-[7px] h-[7px] bg-[#A6DE53] dark:bg-[#418549] ml-[5px]'} />
                        <div className={'w-[7px] h-[7px] bg-[#FAA7A5] dark:bg-[#B81C1E]'} />
                    </div>
                </li>
                <li>
                    <div className={'flex items-center gap-x-[5px]'}>
                        <Text className={'text-body4 text-gray02 dark:text-dark_gray02'}>
                            {t('chat.community.trades.option2')}
                        </Text>
                        <div className={'w-[10px] h-[10px] bg-[#87C853] dark:bg-[#569B4E] ml-[5px]'} />
                        <div className={'w-[10px] h-[10px] bg-[#D56F6E] dark:bg-[#E43126]'} />
                    </div>
                </li>
                <li>
                    <div className={'flex items-center gap-x-[5px]'}>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                            {t('chat.community.trades.option3')}
                        </Text>
                        <div className={'w-[13px] h-[13px] bg-[#6EB252] ml-[5px]'} />
                        <div className={'w-[13px] h-[13px] bg-[#DD504D]'} />
                    </div>
                </li>
                <li>
                    <div className={'flex items-center gap-x-[5px]'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('chat.community.trades.option4')}
                        </Text>
                        <div className={'w-[17px] h-[17px] bg-[#569B4E] dark:bg-[#87C853] ml-[5px]'} />
                        <div className={'w-[17px] h-[17px] bg-[#E43126] dark:bg-[#D56F6E]'} />
                    </div>
                </li>
                <li>
                    <div className={'flex items-center gap-x-[5px]'}>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('chat.community.trades.option5')}
                        </Text>
                        <div className={'w-[20px] h-[20px] bg-[#418549] dark:bg-[#A6DE53] ml-[5px]'} />
                        <div className={'w-[20px] h-[20px] bg-[#B81C1E] dark:bg-[#FAA7A5]'} />
                    </div>
                </li>
            </ul>
        </WizTooltip>
    )
}
export default ChartTooltipItem
