import {BarData, BaseDataFeed, IKlineParams, TSymbolInfo} from './BaseDataFeed'
import axios from 'axios'
import {webSocketManager} from '@component/websocket/websocket'
import {da} from 'date-fns/locale'

export const UPBIT_KLINE_WS = 'wss://api.upbit.com/websocket/v1/ticker'
const UPBIT_BASE_URL = 'https://api.upbit.com/v1'
const count = 200
const intervals = {
    // '1': 'minutes/1',
    // '3': 'minutes/3',
    // '5': 'minutes/5',
    // '15': 'minutes/15',
    // '30': 'minutes/30',
    // '60': 'minutes/60',
    // '240': 'minutes/240',
    '1D': 'days',
    // '1W': 'weeks',
    // '1M': 'months',
}

function mapInterval(interval: string): string {
    return intervals[interval] || 'days'
}

export class UpbitDataFeed extends BaseDataFeed {
    protected socketKey: string

    constructor(socketKey: string) {
        super()
        this.socketKey = socketKey
    }

    public async fetchKlines(params: IKlineParams): Promise<BarData[]> {
        try {
            const interval = mapInterval(params.interval)
            const response = await axios.get(`${UPBIT_BASE_URL}/candles/${interval}`, {
                params: {
                    market: params.symbol,
                    count,
                    to: params.to ? new Date(params.to * 1000).toISOString() : undefined,
                },
            })

            return response.data
                .map(candle => ({
                    time: new Date(candle.candle_date_time_kst).getTime(),
                    open: candle.opening_price,
                    high: candle.high_price,
                    low: candle.low_price,
                    close: candle.trade_price,
                    volume: candle.candle_acc_trade_volume,
                }))
                .reverse()
        } catch (error) {
            console.error(`Failed to fetch Binance klines for ${params.symbol}`, error)
            return []
        }
    }

    protected createChannelString(symbol: string, resolution: string): string {
        return `${symbol}/${mapInterval(resolution) || 'days'}`
    }

    protected subscribeToChannel(channelString: string): void {
        // console.log('[subscribeBars]: Subscribe to upbit streaming. Channel:', channelString)
        const code = channelString.split('/')?.at(0)
        const data = [{ticket: channelString}, {type: 'ticker', codes: [code]}, {format: 'DEFAULT'}]
        void webSocketManager.sendMessage(this.socketKey, data)
    }

    protected unsubscribeFromChannel(channelString: string): void {
        // console.log('[unsubscribeBars]: Unsubscribe from upbit streaming. Channel:', channelString)
        const code = channelString.split('/')?.at(0)
        const data = [{ticket: channelString}, {type: 'ticker', codes: [code]}, {format: 'DEFAULT'}]
        void webSocketManager.sendMessage(this.socketKey, data)
    }

    public async handleStreamData(blob: Blob) {
        try {
            const dataText = await blob.text()
            const dataJson = JSON.parse(dataText)
            if (!dataJson) return

            const channelString = `${dataJson?.code?.toUpperCase()}/${mapInterval('1') || 'days'}`
            const subscriptionItem = this.channelToSubscription?.get?.(channelString)
            if (!subscriptionItem) return
            const bar: BarData = {
                time: dataJson.timestamp,
                open: parseFloat(dataJson.opening_price),
                high: parseFloat(dataJson.high_price),
                low: parseFloat(dataJson.low_price),
                close: parseFloat(dataJson.trade_price),
                volume: parseFloat(dataJson.acc_trade_volume_24h),
            }
            subscriptionItem.handlers.forEach(handler => handler.callback(bar))
        } catch (e) {
            console.log('upbit handleStreamData error: ', e)
        }
    }
}

export async function getAllUpbitSymbols(): Promise<TSymbolInfo[]> {
    try {
        const response = await axios.get(`${UPBIT_BASE_URL}/market/all`)
        return response.data
            .filter(market => market.market.startsWith('KRW-'))
            .map(market => {
                const asset = market.market.split('-')
                const quoteAsset = asset?.at(0)
                const baseAsset = asset?.at(1)
                return {
                    ticker: market.market,
                    name: baseAsset + quoteAsset,
                    korean_name: market.korean_name,
                    english_name: market.english_name,
                    description: `${baseAsset}/${quoteAsset}`,
                    baseAsset,
                    quoteAsset,
                    pricescale: 2,
                    exchange: 'Upbit',
                }
            })
        /*[
            {
                market: 'KRW-BTC',
                korean_name: '비트코인',
                english_name: 'Bitcoin',
            },
        ]*/
    } catch (e) {
        console.log('upbit get all symbols error: ', e)
    }
}
