import {useQuery} from '@tanstack/react-query'
import {getAllBinanceSymbols} from '../datafeed/BinanceDataFeed'

export const useQueryGetBinanceSymbols = (enabled = true) => {
    return useQuery({
        enabled,
        staleTime: 1000 * 60 * 60 * 12, // 12 hour
        cacheTime: 1000 * 60 * 60 * 12, // 12 hour
        queryKey: ['binanceSymbols'],
        queryFn: async () => await getAllBinanceSymbols(),
    })
}
