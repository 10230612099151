import {IBasicDataFeed, ResolutionString, SearchSymbolResultItem} from '../../../public/static/charting_library'
import {
    DEFAULT_BASE_SYMBOL,
    DEFAULT_QUOTE_SYMBOL,
    RESOLVED_SYMBOL_CONFIG,
    BINANCE_WIDGET_CONFIG,
} from '../constants/chartConfig'
import {TTradingViewChartType} from '../component/types/chart'
import {BaseDataFeed, TSymbolInfo} from '../datafeed/BaseDataFeed'
import {barCache} from './barCache'

export function createDatafeed(
    datafeed: BaseDataFeed,
    kind: TTradingViewChartType,
    symbols: TSymbolInfo[],
    setInterval?: (interval: ResolutionString) => void,
): IBasicDataFeed {
    const createSearchSymbolResultItem = (
        symbolItem: TSymbolInfo,
    ): SearchSymbolResultItem & Pick<TSymbolInfo, 'baseAsset' | 'quoteAsset'> => ({
        ...symbolItem,
        symbol: symbolItem.ticker,
        full_name: symbolItem.name,
        exchange: symbolItem.exchange,
        description: symbolItem.description,
        type: 'crypto',
    })

    const createSymbolInfo = (symbolItem: TSymbolInfo): TSymbolInfo => ({
        ...RESOLVED_SYMBOL_CONFIG,
        ticker: symbolItem.ticker,
        name: symbolItem.name,
        description: symbolItem.description,
        exchange: symbolItem.exchange,
        listed_exchange: symbolItem.exchange,
        pricescale: 10 ** symbolItem.pricescale,
        supported_resolutions:
            symbolItem.exchange === 'Upbit'
                ? (['1D'] as ResolutionString[])
                : RESOLVED_SYMBOL_CONFIG.supported_resolutions,
    })

    return {
        onReady: callback => setTimeout(() => callback(BINANCE_WIDGET_CONFIG)),
        searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
            const input = userInput.toLowerCase()
            const matchedSymbols = symbols
                ?.filter(
                    symbol =>
                        (exchange === '' || symbol.exchange === exchange) && symbol.name?.toLowerCase().includes(input),
                )
                .map(createSearchSymbolResultItem)
            onResultReadyCallback(matchedSymbols)
        },
        resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
            const symbolItem = symbols?.find(({name}) => name === symbolName)

            if (!symbolItem) {
                console.log('[resolveSymbol]: Cannot resolve symbol', symbolName)
                const defaultItem = symbols?.find(({name}) => name === DEFAULT_BASE_SYMBOL + DEFAULT_QUOTE_SYMBOL)
                onSymbolResolvedCallback(createSymbolInfo(defaultItem))
                return
            }

            onSymbolResolvedCallback(createSymbolInfo(symbolItem))
        },
        getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
            try {
                await barCache.throttleRequest()
                const data = await datafeed.fetchKlines({
                    ...periodParams,
                    symbol: symbolInfo.ticker,
                    interval: resolution,
                })

                if (!data || data.length === 0) {
                    onHistoryCallback([], {noData: true})
                    return
                }

                // barCache.set(symbolInfo.name, data[data.length - 1].time)
                setInterval?.(resolution)
                onHistoryCallback(data, {noData: false})
            } catch (error) {
                console.error('Error fetching historical data:', error)
                onErrorCallback(error)
            }
        },
        subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
            const subscriberId = `${kind}_${subscriberUID}`

            datafeed.subscribeOnStream(
                symbolInfo,
                resolution,
                onRealtimeCallback,
                subscriberId,
                onResetCacheNeededCallback,
            )
        },

        unsubscribeBars: subscriberUID => datafeed.unsubscribeFromStream(`${kind}_${subscriberUID}`),
    }
}
