import React, {useContext, useMemo} from 'react'
import {IBaseProps} from '@type/page'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import {equalString} from '@util/strings'
import useWindowSize from '@hook/useWindowSize'
import {IProPlan, ProPaymentContext, proPlans} from '../common'
import {createLog} from '@util/logs'

interface IProps extends IBaseProps {}

const ProSelectPlanContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {isSm} = useWindowSize()

    const {plan, setPlan} = useContext(ProPaymentContext)

    const sortedProPlans = useMemo(() => {
        let plans: IProPlan[] = [...proPlans]
        if (isSm) {
            plans?.sort((a, b) => b.month - a.month)
        }
        return plans
    }, [isSm])

    return (
        <div className={`${className} grid grid-cols-3 sm:grid-cols-1 gap-[10px]`}>
            {sortedProPlans?.map(item => (
                <div
                    key={item.id}
                    className={`relative grid grid-cols-1 sm:grid-cols-3 items-center justify-center p-[30px] sm:p-[20px] ring-[1px] rounded-[5px] cursor-pointer ${
                        equalString(plan, item.id)
                            ? 'ring-primary dark:ring-dark_primary hover:bg-primary_tint02 dark:hover:bg-dark_primary_tint02 ring-[2px] bg-primary_tint02 dark:bg-dark_primary_tint02 ring:bg-primary_tint01 dark:ring:bg-dark_primary_tint01 shadow-lg'
                            : 'ring-gray06 dark:ring-dark_gray06 hover:bg-gray07 dark:hover:bg-dark_gray07'
                    }`}
                    onClick={() => {
                        createLog('event', 'account_pro_payment_select_plan', {plan: item.id})
                        setPlan(item.id)
                    }}>
                    <Text className={'text-body2 font-bold text-gray02 dark:text-dark_gray02 text-center'}>
                        {t('landingPayment.selectPlan.items.period.format', {count: item.month})}
                    </Text>
                    <div>
                        <Text className={'text-h1 sm:text-h2 text-center text-gray01 dark:text-dark_gray01'}>
                            {t('landingPayment.selectPlan.items.price', {count: item.price})}
                        </Text>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center'}>
                            {t('landingPayment.selectPlan.items.pricePerMonth', {count: item.price / item.month})}
                        </Text>
                    </div>

                    <div
                        className={`mt-[15px] sm:mt-[0px] ${
                            item.discountPercent ? 'flex' : 'invisible'
                        } items-center justify-center`}>
                        <Text
                            className={
                                'text-primary_shade dark:text-dark_primary_shade bg-primary_tint01 dark:bg-dark_primary_tint01 px-[10px] py-[5px] rounded-[20px] text-body3 font-bold'
                            }>
                            {t('landingPayment.selectPlan.items.discountTag', {count: item.discountPercent})}
                        </Text>
                    </div>

                    {item.isBestPlan && (
                        <div
                            className={
                                'absolute top-[-5px] right-[-5px] bg-primary dark:bg-dark_primary px-[10px] py-[3px] rounded-[3px]'
                            }>
                            <Text className={'text-body3 font-bold text-white'}>
                                {t('landingPayment.selectPlan.items.bestValueTag.text')}
                            </Text>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default ProSelectPlanContainer
