import {Direction, IChartingLibraryWidget, widget} from '../../../public/static/charting_library'
import {TOrderInfo, TOrderItem} from '../hooks/useOrderNats'
import {getColor, getFontSize, getHeight} from './order'
import {SPECIAL_CHAR_REGEX} from '@constant/reg'

export function createTvWidget(widgetOptions: any): IChartingLibraryWidget {
    try {
        return new widget(widgetOptions)
    } catch (e) {
        console.log(`[createTvWidget] Error: ${e.message}`)
    }
}

export async function createChartMarks(
    tvWidget: IChartingLibraryWidget,
    order?: TOrderItem,
    isDark?: boolean,
    symbol?: TOrderInfo,
) {
    if (!order) return
    const orderType = order.order_type

    const orderSymbol = order.base_symbol || order.symbol.replace(SPECIAL_CHAR_REGEX, '')
    if (!orderSymbol.includes(symbol.base_symbol)) return
    if (orderType !== 'buy' && orderType !== 'sell') return
    if (order.username === 'Anonymous') return

    const investAmount = order.amount * order.price
    const isBuy = orderType === 'buy'
    tvWidget?.onChartReady?.(async () => {
        try {
            await tvWidget?.headerReady?.()?.then(() => {
                const shape = tvWidget.activeChart().createExecutionShape()

                shape
                    .setText(`${orderType} @${order?.username || ''}`)
                    .setTooltip(
                        `${orderType} @${order?.username || ''}, ${order.price}, ${order.amount}${order.symbol}`,
                    )
                    .setTextColor(getColor(investAmount, isBuy, isDark))
                    .setArrowColor(getColor(investAmount, isBuy, isDark))
                    .setDirection(orderType as Direction)
                    .setPrice(order.price)
                    .setTime(order.created_at)
                    .setArrowHeight(getHeight(investAmount))
                    .setArrowSpacing(-1)
                    .setFont(getFontSize(investAmount))
            })
        } catch (e) {
            console.log(`[createChartMarks] Error : ${e.message}`)
        }
    })
}
