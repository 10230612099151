export const REQUEST_LIMIT_MS = 200 // 요청 간 최소 간격 (ms)
export const delay = (ms: number): Promise<void> => new Promise<void>(resolve => setTimeout(() => resolve(), ms))

class BarCache {
    private static instance: BarCache
    private cache: Map<string, any>
    private lastRequestTime: number

    private constructor() {
        this.cache = new Map()
        this.lastRequestTime = 0
    }

    static getInstance() {
        if (!BarCache.instance) {
            BarCache.instance = new BarCache()
        }
        return BarCache.instance
    }

    get(key: string) {
        return this.cache.get(key)
    }

    set(key: string, value: any) {
        this.cache.set(key, value)
    }

    async throttleRequest() {
        const now = Date.now()
        if (now - this.lastRequestTime < REQUEST_LIMIT_MS) {
            await delay(REQUEST_LIMIT_MS - (now - this.lastRequestTime))
        }
        this.lastRequestTime = Date.now()
    }
}

export const barCache = BarCache.getInstance()
