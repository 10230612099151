import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import WizContentModal from '@component/modal/WizContentModal'
import WizTextButton from '@component/buttons/WizTextButton'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'
import {createLog} from '@util/logs'

interface IProps {
    exchange: string
}

const ProIpUpdateModal = ({exchange}: IProps, ref: any) => {
    const {t} = useTranslation()
    const modalRef = useRef<any>()
    const {copyToClipboard} = useCopy()
    const {getExchange} = useQueryGetExchanges()
    const {showSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    return (
        <WizContentModal size={'400'} title={t('modal.updateProIP.title')} ref={modalRef}>
            <Text className={'text-gray02 dark:text-dark_gray02 text-body2 mt-[10px]'}>
                {t('modal.updateProIP.desc')}
            </Text>

            <Text
                className={
                    'text-body2 text-gray02 dark:text-dark_gray02 p-[15px] border border-gray07 dark:border-dark_gray07 rounded-[3px] mt-[10px] bg-gray09 dark:bg-dark_gray09 break-all'
                }>
                {getExchange(exchange)?.ip}
            </Text>
            <WizButton
                className={'w-full mt-[10px]'}
                buttonType={'primary'}
                text={t('modal.updateProIP.copyToClipboard')}
                onClick={() => {
                    createLog('event', 'account_copy_pro_ip')
                    copyToClipboard(getExchange(exchange)?.ip)
                    showSnackbar(t('common.copied'))
                }}
            />

            <div className={'flex justify-center mt-[10px]'}>
                <WizTextButton
                    className={'text-gray02 dark:text-dark_gray02 text-body2'}
                    text={t('modal.updateProIP.updateIpOnExchange', {exchange: getExchange(exchange)?.display_name})}
                    onClick={() => window.open(getExchange(exchange)?.ref_url, '_blank', 'noopener, noreferrer')}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(ProIpUpdateModal)
