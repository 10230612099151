import {LibrarySymbolInfo} from '../../../public/static/charting_library'

export interface BarData {
    time: number
    open: number
    high: number
    low: number
    close: number
    volume: number
}

export type TSymbolInfo = LibrarySymbolInfo & {
    baseAsset?: string
    quoteAsset?: string
    korean_name?: string
    english_name?: string
}

export interface IKlineParams {
    symbol: string
    interval: string
    from?: number
    to?: number
}

export interface SubscriberData {
    subscriberUID: string
    resolution: string
    handlers: {
        id: string
        callback: (bar: BarData) => void
    }[]
}

export abstract class BaseDataFeed {
    protected socketKey: string
    protected channelToSubscription: Map<string, SubscriberData> = new Map()

    constructor() {}

    public subscribeOnStream(
        symbolInfo: LibrarySymbolInfo,
        resolution: string,
        onRealtimeCallback: (bar: BarData) => void,
        subscriberUID: string,
        onResetCacheNeededCallback?: () => void,
    ): void {
        const channelString = this.createChannelString(symbolInfo.ticker, resolution)

        const handler = {
            id: subscriberUID,
            callback: onRealtimeCallback,
        }

        let subscriptionItem = this.channelToSubscription.get(channelString)

        if (subscriptionItem) {
            subscriptionItem.handlers.push(handler)
            return
        }

        subscriptionItem = {
            subscriberUID,
            resolution,
            handlers: [handler],
        }

        this.channelToSubscription.set(channelString, subscriptionItem)
        this.subscribeToChannel(channelString)
    }

    public unsubscribeFromStream(subscriberUID: string): void {
        for (const channelString of this.channelToSubscription.keys()) {
            const subscriptionItem = this.channelToSubscription.get(channelString)

            if (!subscriptionItem) continue

            const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID)

            if (handlerIndex !== -1) {
                subscriptionItem.handlers.splice(handlerIndex, 1)

                if (subscriptionItem.handlers.length === 0) {
                    this.unsubscribeFromChannel(channelString)
                    this.channelToSubscription.delete(channelString)
                    break
                }
            }
        }
    }

    abstract fetchKlines(params: IKlineParams): Promise<BarData[]>
    abstract handleStreamData(data: any): void
    protected abstract createChannelString(symbol: string, resolution: string): string
    protected abstract subscribeToChannel(channelString: string): void
    protected abstract unsubscribeFromChannel(channelString: string): void
}
