import {useEffect, useRef, useState} from 'react'
import {BaseDataFeed} from '../datafeed/BaseDataFeed'
import {BINANCE_KLINE_WS} from '../datafeed/BinanceDataFeed'
import {dataFeedFactory} from '../datafeed/DataFeedFactory'
import {WebSocketConfig, webSocketManager} from '@component/websocket/websocket'
import {calculateReconnectInterval} from '@util/interval'

const exchange = 'binance'
export const useBinanceExchangeData = () => {
    const datafeed = useRef<BaseDataFeed>()
    const [errorCounter, setErrorCounter] = useState(0)

    const onClose = () => {
        if (errorCounter > 10) return
        const nextInterval = calculateReconnectInterval(errorCounter)
        setTimeout(() => setErrorCounter(prev => prev + 1), nextInterval)
        console.log('Attempting to connect websocket..., try: ', errorCounter)
    }

    useEffect(() => {
        const binanceDatafeed = dataFeedFactory.getDataFeed(exchange)
        datafeed.current = binanceDatafeed

        const binanceConfig: WebSocketConfig = {
            url: BINANCE_KLINE_WS,
            handlers: {
                onMessage: data => {
                    try {
                        const parsedData = JSON.parse(data)
                        binanceDatafeed.handleStreamData(parsedData)
                    } catch (error) {
                        console.error('Binance WebSocket message error', error)
                    }
                },
                onClose,
            },
        }

        void webSocketManager.createWebSocket(exchange, binanceConfig)

        return () => webSocketManager.closeWebSocket(exchange)
    }, [errorCounter])

    return {binanceDatafeed: datafeed.current}
}
