import {BaseDataFeed} from './BaseDataFeed'
import {BinanceDataFeed} from './BinanceDataFeed'
import {UpbitDataFeed} from './UpbitDataFeed'

export type TChartExchangeType = 'binance' | 'upbit'

class DataFeedFactory {
    private static instance: DataFeedFactory
    private instances: {[key: string]: BaseDataFeed} = {}

    private constructor() {}

    public static getInstance(): DataFeedFactory {
        if (!this.instance) {
            this.instance = new DataFeedFactory()
        }
        return this.instance
    }

    public getDataFeed(exchange: TChartExchangeType): BaseDataFeed {
        if (!this.instances[exchange]) {
            switch (exchange) {
                case 'binance':
                    this.instances[exchange] = new BinanceDataFeed(exchange)
                    break
                case 'upbit':
                    this.instances[exchange] = new UpbitDataFeed(exchange)
                    break
                default:
                    throw new Error(`Unsupported exchange: ${exchange}`)
            }
        }
        return this.instances[exchange]
    }
}

export const dataFeedFactory = DataFeedFactory.getInstance()
