import React, {useContext, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import {Web3} from 'web3'
import useQueryGetDepositAddressCrypto from '@hook/query/user/account/wallet/useQueryDepositAddressCrypto'
import {ProPaymentContext} from '../common'
import Text from '@component/Text/Text'
import StepIndicator from '@feature/copy/components/StepIndicator/StepIndicator'
import WizImage from '@component/images/WizImage'
import LoadingImage from '@image/img_pay_external_crypto_modal_loading.png'
import LoadingImageDark from '@image/img_pay_external_crypto_modal_loading_dark.png'
import useSnackbar from '@hook/useSnackbar'
import IconBack from '@svg/common/ic_btn_back.svg'
import IconMetaMask from '@svg/wallet/ic_metamask.svg'
import useDark from '@hook/useDark'

/*
  현재는 Pro 결제에만 사용되지만, 추후에는 서비스 전체에서 사용될 수 있다.
 */

interface IProps {}

enum PageSTEP {
    STEP_1,
    STEP_2,
}

const ProPaymentExternalCryptoModal = ({}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {selectedPlan, paymentNetwork, setIsProPaid, isSupportMetamask, isSupportCoinbaseWallet} =
        useContext(ProPaymentContext)
    const {data: depositAddresses} = useQueryGetDepositAddressCrypto('lot-pro', paymentNetwork)
    const [step, setStep] = useState<PageSTEP>(PageSTEP.STEP_1)
    const {showSnackbar} = useSnackbar()
    const {isRenderDark} = useDark()

    useImperativeHandle(ref, () => ({
        show: () => {
            setStep(PageSTEP.STEP_1)
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const onClickPayByMetaMask = async () => {
        try {
            setStep(PageSTEP.STEP_2)
            const MetaMaskSDK = (await import('@metamask/sdk')).default
            const MMSDK = new MetaMaskSDK()
            const ethereum = MMSDK.getProvider()

            const provider = ethereum.providers?.find(p => p.isMetaMask) || ethereum

            if (!provider || !provider.isMetaMask) return

            const accounts = await provider.request({method: 'eth_requestAccounts', params: []})

            await provider
                .request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: accounts[0],
                            to: '0xdAC17F958D2ee523a2206206994597C13D831ec7', // USDT Contract
                            data: new Web3(ethereum).eth.abi.encodeFunctionCall(
                                {
                                    name: 'transfer',
                                    type: 'function',
                                    inputs: [
                                        {
                                            name: '_to',
                                            type: 'address',
                                        },
                                        {
                                            name: '_value',
                                            type: 'uint256',
                                        },
                                    ],
                                    outputs: [],
                                },
                                [depositAddresses.address, selectedPlan?.price * 1000000],
                            ),
                        },
                    ],
                })
                .then(txHash => {
                    setIsProPaid(true)
                    modalRef?.current?.close()
                })
        } catch (err) {
            showSnackbar(err)
        }
    }

    const modalTitle = useMemo(() => {
        switch (step) {
            case PageSTEP.STEP_1:
                return t('landingPaymentExternalModal.step1.title')
            case PageSTEP.STEP_2:
                return t('landingPaymentExternalModal.step2.title')
        }
    }, [t, step])

    return (
        <WizContentModal size={'600'} title={modalTitle} ref={modalRef}>
            {step === PageSTEP.STEP_1 && (
                <div className={'mt-[10px]'}>
                    <div className={'mt-[10px] flex justify-between items-center space-x-[10px]'}>
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01'}>
                            {t('landingPaymentExternalModal.step1.desc')}
                        </Text>
                        <StepIndicator totalStep={3} currentStep={0} />
                    </div>
                    <div className={'grid grid-cols-2 sm:grid-cols-1 gap-[10px] mt-[30px] mb-[20px]'}>
                        {isSupportMetamask && (
                            <button
                                className={
                                    'border border-gray07 dark:border-dark_gray07 rounded-[5px] p-[20px] flex flex-col justify-center items-center gap-[10px]'
                                }
                                onClick={onClickPayByMetaMask}>
                                <IconMetaMask className={'w-[40px]'} />
                                <Text className={'text-body-2 text-gray dark:text-dark_gray01'}>
                                    {t('landingPaymentExternalModal.step1.metamask.text')}
                                </Text>
                            </button>
                        )}
                    </div>
                </div>
            )}
            {step === PageSTEP.STEP_2 && (
                <div className={'mt-[10px]'}>
                    <div className={'mt-[10px] flex justify-between items-center space-x-[10px]'}>
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01'}>
                            {t('landingPaymentExternalModal.step2.desc')}
                        </Text>
                        <StepIndicator totalStep={3} currentStep={1} />
                    </div>
                    <div className={'mt-[30px]'}>
                        <Text className={'text-body1 font-bold text-center text-gray dark:text-dark_gray01'}>
                            {t('landingPaymentExternalModal.step2.connecting')}
                        </Text>
                        <div className={'flex gap-[20px] justify-center'}>
                            {isRenderDark ? (
                                <WizImage src={LoadingImageDark} width={274} />
                            ) : (
                                <WizImage src={LoadingImage} width={274} />
                            )}
                        </div>
                        <div>
                            <button
                                className={'flex items-center gap-x-[3px]'}
                                onClick={() => setStep(PageSTEP.STEP_1)}>
                                <IconBack className={'w-[12px] fill-gray02 dark:fill-dark_gray02'} />
                                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 underline'}>
                                    {t('landingPaymentExternalModal.step2.back')}
                                </Text>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </WizContentModal>
    )
}

export default React.forwardRef(ProPaymentExternalCryptoModal)
