import React, {useContext} from 'react'
import {IBaseProps} from '@type/page'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import IconSuccess from '@svg/common/ic_noti_confirm.svg'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {ProPaymentContext} from '../common'
import useFormatNumber from '@hook/useFormatNumber'
import {createLog} from '@util/logs'

interface IProps extends IBaseProps {}

const ProPaymentCompleteContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {data: me} = useQueryFetchMe()
    const {goLeaderboard, goUserPortfolio, goProLanding} = useNavigation()
    const {selectedPlan, paymentCurrency} = useContext(ProPaymentContext)
    const {displayBalance} = useFormatNumber()
    return (
        <div className={`${className}`}>
            <Text className={'text-h3 text-gray01 dark:text-dark_gray01'}>
                {t('landingPayment.paymentComplete.title')}
            </Text>

            <div className={'flex flex-col justify-center items-center mt-[10px]'}>
                <IconSuccess className={'w-[70px] fill-primary dark:fill-dark_primary'} />
                <Text className={'text-h4 mt-[30px] text-gray01 dark:text-dark_gray01'}>
                    {t('landingPayment.paymentComplete.thanks.title')}
                </Text>
                <Text className={'text-body1 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                    {t('landingPayment.paymentComplete.thanks.desc')}
                </Text>
                <div className={'hidden sm:flex flex-col justify-center items-center mt-[40px]'}>
                    <Text className={'text-gray02 dark:text-dark_gray02'}>
                        {t('landingPayment.paymentCompleteSummary.period.format', {count: selectedPlan.month})}
                    </Text>
                    <Text className={'text-h2 text-gray01 dark:text-dark_gray01'}>{`${
                        selectedPlan.price
                    } ${paymentCurrency?.toUpperCase()}`}</Text>

                    <Text className={'text-center text-gray03 dark:text-dark_gray03'}>
                        ≈
                        {displayBalance(selectedPlan.price, {
                            showPreSign: true,
                        })}
                    </Text>
                </div>
                <div className={'grid grid-cols-1 mt-[50px] sm:mt-[40px] gap-[10px]'}>
                    <WizButton
                        buttonType={'primary'}
                        size={'large'}
                        text={t('landingPayment.paymentComplete.goMyPortfolio')}
                        onClick={() => {
                            createLog('event', 'account_payment_complete', {user_id: me?.id})
                            goUserPortfolio(me?.id)
                        }}
                    />
                </div>
                <button className={'mt-[20px]'} onClick={goProLanding}>
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 underline'}>
                        {t('landingPayment.paymentComplete.goFaqText')}
                    </Text>
                </button>
            </div>
        </div>
    )
}

export default ProPaymentCompleteContainer
