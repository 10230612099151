import PortfolioLayout from '@feature/portfolio/components/layouts/PortfolioLayout/PortfolioLayout'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import React from 'react'
import CopyBalanceHeader from '@feature/portfolio/pages/userCopyfolio/CopyBalanceHeader/CopyBalanceHeader'
import CopyfolioChartContainer from '@feature/portfolio/pages/userCopyfolio/CopyChartContainer/CopyfolioChartContainer'
import EmptyCopyfolioContainer from '@feature/portfolio/pages/userCopyfolio/EmptyCopyfolioContainer/EmptyCopyfolioContainer'
import CopyOptionHeaderContainer from '@feature/portfolio/pages/userCopyfolio/CopyOptionHeaderContainer/CopyOptionHeaderContainer'
import Head from 'next/head'
import {useTranslation} from 'next-i18next'
import PageConfig from '@component/layouts/PageConfig/PageConfig'
import {dehydrate, QueryClient} from '@tanstack/react-query'
import {fetchExchanges} from '@api/core/exchange/exchange'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetSidebarUserID} from '@api/user/sidebar/getSidebar'
import {apiGetProfile} from '@api/user/profile/getProfile'
import {equalString} from '@util/strings'
import useQueryGetUserCopyDashboard from '@hook/query/portfolio/copyfolio/useQueryGetCopyDashboard'
import {apiGetCopierDashboard} from '@api/copy/copier/dashboard/getCopyDashboard'
import {apiGetCopierGraphForUser} from '@api/user/profile/copy/getCopierGraph'
import {DayDuration} from '@type/time'
import dynamic from 'next/dynamic'
import RecommendCopyTradingContainer from '@feature/portfolio/pages/userCopyfolio/UsedBeforeCopyfolioContainer/RecommendCopyTradingContainer'
import useQueryGetCopyHistory from '@hook/query/portfolio/copyfolio/useQueryGetCopyHistory'
import MoreButton from '@feature/common/components/MoreButton/MoreButton'
import useNavigation from '@hook/useNavigation'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'
import HeaderProBadge from '@feature/portfolio/components/HeaderProBadge/HeaderProBadge'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import useScreenLog from '@hook/useScreenLog'
import {createLog} from '@util/logs'
import ProNotice from '@feature/portfolio/components/ProNotice/ProNotice'
import Link from 'next/link'
import {getLinkUrl, URL_LEADERBOARD_COPY} from '@constant/url'
import IconPlus from '@svg/common/ic_btn_add.svg'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {apiGetCopyHistory} from '@api/copy/copier/history/getCopyHistory'

export const InvoiceNoticeCSR = dynamic(import('@feature/portfolio/components/InvoiceNotice/InvoiceNotice'), {
    ssr: false,
})

const InvoiceIncomingNoticeCSR = dynamic(import('@feature/portfolio/components/InvoiceNotice/InvoiceIncomingNotice'), {
    ssr: false,
})

const CopyfolioAssetTableContainerCSR = dynamic(
    import('@feature/portfolio/pages/userCopyfolio/CopyfolioAssetTableContainer/CopyfolioAssetTableContainer'),
    {
        ssr: false,
    },
)

const CopyPortfolioPage = ({login_id, id, isMe, isHasCopyHistory}) => {
    const {} = useScreenLog({pageName: 'user_copy_dashboard'})
    const {t} = useTranslation()
    const {goLeaderboard} = useNavigation()

    const {data: copyDashboard} = useQueryGetUserCopyDashboard(id, PORTFOLIO_REFRESH_INTERVAL)
    const {data: copyHistory, refetch: refetchHistory, isLoading, isFetching, isSuccess} = useQueryGetCopyHistory(id)
    const {data: me} = useQueryFetchMe(undefined, false)

    useDidMountEffect(() => {
        refetchHistory()
    }, [copyDashboard?.copier_key_asset])

    return (
        <PageConfig
            enableFloatingNavigation={true}
            mainClassName={'bg-white dark:bg-bg_dark_background'}
            requireLogin={true}>
            <Head>
                <title>{t('portfolio.title.copy')}</title>
            </Head>
            <PortfolioLayout
                login_id={login_id}
                id={id}
                bgContainerClassName={'bg-blue_tint02 dark:bg-dark_blue_tint02 pt-[50px] md:pt-[60px]'}
                showNavigation={isMe}
                isMe={isMe}>
                {(copyDashboard?.copier_key_asset?.length ?? 0) === 0 && copyHistory?.length === 0 && (
                    <EmptyCopyfolioContainer login_id={login_id} id={id} />
                )}
                {(copyDashboard?.copier_key_asset?.length ?? 0) === 0 &&
                    (isHasCopyHistory || copyHistory?.length > 0) && (
                        <RecommendCopyTradingContainer
                            isMe={isMe}
                            id={id}
                            copyHistory={copyHistory}
                            isFetching={isLoading && isFetching && !isSuccess}
                        />
                    )}

                {copyDashboard?.copier_key_asset?.length > 0 && (
                    <>
                        {isMe && <ProNotice className={'mt-[20px]'} />}
                        {isMe && <HeaderProBadge />}
                        <CopyBalanceHeader userID={id} />
                        <CopyfolioChartContainer type={'user'} id={id} />
                        {isMe && <InvoiceNoticeCSR className={'mt-[20px]'} />}
                        {isMe && <InvoiceIncomingNoticeCSR className={'mt-[20px]'} />}
                        <CopyOptionHeaderContainer className={'mt-[20px]'} userId={id} isPro={me?.is_pro && isMe} />
                        <CopyfolioAssetTableContainerCSR className={'mt-[10px]'} userID={id} />
                        <Link href={getLinkUrl(URL_LEADERBOARD_COPY)}>
                            <MoreButton
                                onClick={() => {
                                    createLog('event', 'copyfolio_click_more_button')
                                }}
                                text={t('copyfolio.copyleader.button.more')}
                                className={'min-w-[auto] px-[30px] mx-auto mt-[20px]'}
                                renderLeft={<IconPlus width={16} className={'fill-gray01 dark:fill-dark_gray01'} />}
                            />
                        </Link>
                    </>
                )}
            </PortfolioLayout>
        </PageConfig>
    )
}

export const getServerSideProps = async ctx => {
    const cookies = ctx.req.cookies
    const locale = cookies.language || ctx.locale || 'en'
    const loginId = cookies.userId ?? ''
    const userId = ctx.query.id ?? ''
    if (!loginId) {
        return {
            redirect: {
                permanent: false,
                destination: `/${locale}/login`,
            },
        }
    }

    const queryClient = new QueryClient()

    const {data: exchanges} = await fetchExchanges({cookie: cookies})
    queryClient.setQueryData(QueryCacheKeys.exchange.getList(), exchanges)

    const {data: sidebar} = await apiGetSidebarUserID(userId, {cookie: cookies})
    queryClient.setQueryData(QueryCacheKeys.portfolio.getUserSidebar(userId, locale), sidebar)
    if (!sidebar) {
        return {
            redirect: {
                permanent: false,
                destination: `/`,
            },
        }
    }

    const {data: userProfile} = await apiGetProfile(userId, {cookie: cookies})
    queryClient.setQueryData(QueryCacheKeys.portfolio.getUserProfile(userId), userProfile)

    const {data: copyDashboard} = await apiGetCopierDashboard(sidebar?.user_id, {cookie: cookies})
    queryClient.setQueryData(QueryCacheKeys.copyfolio.getUserCopyDashboard(sidebar?.user_id), copyDashboard)

    const {data: copyDashboardChart} = await apiGetCopierGraphForUser(sidebar?.user_id, DayDuration.Lifetime, {
        cookie: cookies,
    })

    queryClient.setQueryData(
        QueryCacheKeys.copyfolio.getUserCopyChart(sidebar?.user_id, DayDuration.Lifetime),
        copyDashboardChart,
    )

    const isMe = equalString(loginId, userId)
    const {data: copyHistory} = await apiGetCopyHistory(userId, {cookie: cookies})
    if (!isMe) {
        return {
            redirect: {
                permanent: false,
                destination: `/${locale}/user/${sidebar?.user_id}/portfolio`,
            },
        }
    } else {
        return {
            props: {
                login_id: loginId,
                id: userId,
                isMe: isMe,
                isHasCopyHistory: copyHistory?.length,
                dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
                ...(await serverSideTranslations(locale, ['common'])),
            },
        }
    }
}

export default CopyPortfolioPage
